/* eslint-disable no-useless-catch */

/**
 * Auth service
 */

// Services
import BaseService from 'configs/service';
import { CheckCompanyRequest } from 'models/ChangeCompanyRequest';
import { ChangeEmailVerifyRequest } from 'models/ChangeEmailVerifyRequest';
import { ChangeEmailRequest } from 'models/ChangeEmailRequest';
import { ChangePasswordRequest } from 'models/ChangePasswordRequest';
import { EmailExistsRequest } from 'models/EmailExistsRequest';
import { GetAddressRequest } from 'models/GetAddressRequest';
import { LoginRequest } from 'models/LoginRequest';
import { NewPasswordRequest } from 'models/NewPasswordRequest';
import { RegisterRequest } from 'models/RegisterRequest';
import { RegisterServiceRequest } from 'models/RegisterServiceRequest';
import { EmailConfirmationRequest } from 'models/EmailConfirmationRequest';
import { VerifyEmailRequest } from 'models/VerifyEmailRequest';
import { CheckPasswordRequest } from 'models/CheckPasswordRequest';
import { ChangeUserInfoRequest } from 'models/ChangeUserInfoRequest';
import { RefreshTokenRequest } from 'models/RefreshTokenRequest';

// Utils
import { HTTP_METHOD, URL_GET_ADDRESS } from 'utils/constants';
import { GenerateTokenRequest } from 'models/GenerateTokenRequest';

const ENDPOINTS = {
  API_LOGIN: ['/login', HTTP_METHOD.POST],
  API_REGISTER: ['/register/detail', HTTP_METHOD.POST],
  API_REGISTER_TEM: ['/register/send-verification', HTTP_METHOD.POST],
  API_VERIFY_EMAIL: ['/register/verify', HTTP_METHOD.POST],
  API_RESET_PASSWORD: ['/password/forgot/confirm', HTTP_METHOD.POST],
  APP_GET_ADDRESS: [
    `${URL_GET_ADDRESS.ADDRESS_URL}/$0?apikey=${URL_GET_ADDRESS.ADDRESS_API_KEY}`,
    HTTP_METHOD.GET,
  ],
  API_GET_SERVICES: ['/services', HTTP_METHOD.GET],
  API_REGISTER_SERVICE: ['/services/register', HTTP_METHOD.POST],
  API_FORGOT_PASSWORD: ['/password/forgot', HTTP_METHOD.POST],
  API_GET_USER_INFO: ['/userinfo', HTTP_METHOD.GET],
  API_CHECK_EMAIL_EXISTS: ['/email/check', HTTP_METHOD.POST],
  API_CHANGE_EMAIL: ['/email/change', HTTP_METHOD.POST],
  API_CHANGE_EMAIL_VERIFY: ['/email/verify-change', HTTP_METHOD.POST],
  API_CHANGE_PASSWORD: ['/password/change', HTTP_METHOD.POST],
  API_CHECK_COMPANY: ['/company/check', HTTP_METHOD.POST],
  API_CHECK_PASSWORD: ['/password/check', HTTP_METHOD.POST],
  API_LOGOUT: ['/logout', HTTP_METHOD.POST],
  API_CHANGE_USER_INFO: ['/userinfo', HTTP_METHOD.PUT],
  API_REFRESH_TOKEN: ['/token/refresh', HTTP_METHOD.POST],
  API_GENERATE_AUTHEN_CODE: ['/auth/code', HTTP_METHOD.POST],
};

class AuthService extends BaseService {
  constructor() {
    super();
  }

  async login(data: LoginRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_LOGIN, [], data);
      return results.data?.results;
    } catch (error: any) {
      throw error;
    }
  }

  async register(data: RegisterRequest) {
    const results = await this.request(ENDPOINTS.API_REGISTER, [], data);
    return results.data;
  }

  async registerTem(data: EmailConfirmationRequest) {
    const results = await this.request(ENDPOINTS.API_REGISTER_TEM, [], data);
    return results.data;
  }

  async verifyEmail(data: VerifyEmailRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_VERIFY_EMAIL, [], data);
      return results.data.results;
    } catch (error) {
      throw error;
    }
  }

  async forgotPassword(data: EmailConfirmationRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_FORGOT_PASSWORD, [], data);
      return results.data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(data: NewPasswordRequest) {
    const results = await this.request(ENDPOINTS.API_RESET_PASSWORD, [], data);
    return results.data;
  }

  async getAddress(data: GetAddressRequest) {
    try {
      const results = await this.request(ENDPOINTS.APP_GET_ADDRESS, [data.zip_code]);
      return results.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserInfo() {
    try {
      const results = await this.request(ENDPOINTS.API_GET_USER_INFO, []);
      return results.data.results;
    } catch (error) {
      throw error;
    }
  }
  async getServices() {
    try {
      const results = await this.request(ENDPOINTS.API_GET_SERVICES, []);
      return results.data.results;
    } catch (error) {
      throw error;
    }
  }
  async registerService(data: RegisterServiceRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_REGISTER_SERVICE, [], data);
      return results.data.results;
    } catch (error) {
      throw error;
    }
  }

  async checkEmailExists(data: EmailExistsRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_CHECK_EMAIL_EXISTS, [], data);
      return results;
    } catch (error) {
      throw error;
    }
  }

  async changeEmail(data: ChangeEmailRequest) {
    const results = await this.request(ENDPOINTS.API_CHANGE_EMAIL, [], data);
    return results?.data;
  }

  async changeEmailVerify(data: ChangeEmailVerifyRequest) {
    const results = await this.request(ENDPOINTS.API_CHANGE_EMAIL_VERIFY, [], data);
    return results?.data;
  }

  async changePassword(data: ChangePasswordRequest) {
    const results = await this.request(ENDPOINTS.API_CHANGE_PASSWORD, [], data);
    return results?.data;
  }

  async checkCompany(data: CheckCompanyRequest) {
    try {
      const result = await this.request(ENDPOINTS.API_CHECK_COMPANY, [], data);
      return result.data.results;
    } catch (error) {
      throw error;
    }
  }
  async checkPassword(data: CheckPasswordRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_CHECK_PASSWORD, [], data);
      return results;
    } catch (error: any) {
      throw error;
    }
  }

  async logout() {
    const results = await this.request(ENDPOINTS.API_LOGOUT, []);
    localStorage.clear();
    return results;
  }

  async changeUserInfo(data: ChangeUserInfoRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_CHANGE_USER_INFO, [], data);
      return results?.data;
    } catch (error: any) {
      throw error;
    }
  }

  async refreshToken(data: RefreshTokenRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_REFRESH_TOKEN, [], data);
      return results?.data?.results?.access_token;
    } catch (error: any) {
      throw error;
    }
  }

  async generateAuthenCode(data: GenerateTokenRequest) {
    try {
      const results = await this.request(ENDPOINTS.API_GENERATE_AUTHEN_CODE, [], data);
      return results?.data?.results;
    } catch (error: any) {
      throw error;
    }
  }
}

export default new AuthService();
