/**
 * Header component
 */

// React
import React from 'react';

// Library
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// MUI Components
import { styled } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  ListItemText,
  Menu,
  IconButton,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';

// Utils
import { AWS_S3, FORM_LABELS, MENU_LABELS, STORAGE_KEYS, URL_PRIVACY_TERM } from 'utils/constants';

// Router
import ROUTES from 'configs/route';

// Service
import AuthService from 'services/AuthService';

// Styles
const HeaderContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px 30px',
  boxShadow: '0px 0px 8px 0px #cccc',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));

const Logo = styled('img')({
  width: 'auto',
  height: '22px',
  objectFit: 'cover',
  cursor: 'pointer',
});

const MenuItems = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

const LoginButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  height: '30px',
  width: '100px',
  padding: '0px 20px',
  borderRadius: '30px',
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '11px',
  },
}));

const WrapperMenu = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const UserID = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

const BlockNameAndEmail = styled(Box)({
  padding: '26px',
  width: '100%',

  '& p': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const BlockName = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
});

const BlockEmail = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const BlockLogout = styled(Box)({
  padding: '26px',
  width: '100%',

  '& span': {
    fontSize: '12px',
    fontWeight: '600',
  },
});

const BlockHandleUserInfo = styled(Box)({
  padding: '16px 0 ',
  width: '100%',
});

const BlockHandleUserInfoItem = styled(Box)({
  padding: '10px 26px',
  width: '100%',

  '& span': {
    fontSize: '12px',
    fontWeight: '600',
  },
});

// Component
const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [accessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const [refreshToken] = useLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);

  const resetUserInfo = useResetRecoilState(userInfoAtom);
  const setIsLoading = useSetRecoilState(isLoading);
  const { last_name = '', first_name = '', email = '' } = useRecoilValue(userInfoAtom);
  const fullName = last_name + '　' + first_name;

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoginClick = () => {
    navigate(ROUTES.LOGIN);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    handleClose();
    try {
      setIsLoading(true);
      await AuthService.logout();
      resetUserInfo();
      setIsLoading(false);
      navigate(ROUTES.HOME);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handlePrivacyPolicyClick = () => {
    handleClose();
    window.open(URL_PRIVACY_TERM.PRIVACY_POLICY_URL, '_blank');
  };

  const handleTermOfUseClick = () => {
    handleClose();
    window.open(URL_PRIVACY_TERM.TERM_OF_USE_URL, '_blank');
  };

  const handleAccountInfoClick = () => {
    handleClose();
    const navigatePage = setTimeout(() => {
      navigate(ROUTES.USER_INFO);
    }, 0);
    return () => {
      clearTimeout(navigatePage);
    };
  };

  const handleNavigateDashboard = () => {
    if (accessToken && refreshToken) {
      navigate(ROUTES.DASHBOARD);
    } else {
      navigate(ROUTES.HOME);
    }
  };

  return (
    <HeaderContainer>
      <Logo onClick={handleNavigateDashboard} src={`${AWS_S3.LOGO_PNG}`} alt="App Logo" />
      <MenuItems>
        {/* TODO:
          <MenuItem href="#home">Home</MenuItem>
          <MenuItem href="#about">About</MenuItem>
        */}
      </MenuItems>
      {!accessToken || !refreshToken ? (
        <LoginButton variant="contained" color="primary" onClick={handleLoginClick}>
          {FORM_LABELS.LOGIN}
        </LoginButton>
      ) : (
        <>
          <WrapperMenu>
            <UserID variant="h4">{MENU_LABELS.USER_ID}</UserID>
            <IconButton onClick={handleClick}>
              <MenuIcon />
            </IconButton>
          </WrapperMenu>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: { width: 235, padding: 0, marginTop: 4.5, borderRadius: '10px' },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Box sx={{ background: alpha(theme.palette.primary.main, 0.2), width: '100%' }}>
                <BlockNameAndEmail>
                  <BlockName>{fullName}</BlockName>
                  <BlockEmail>{email}</BlockEmail>
                </BlockNameAndEmail>
              </Box>
            </MenuItem>
            <BlockHandleUserInfo>
              <MenuItem onClick={handleAccountInfoClick}>
                <BlockHandleUserInfoItem>
                  <ListItemText>{MENU_LABELS.ACCOUNT_INFORMATION}</ListItemText>
                </BlockHandleUserInfoItem>
              </MenuItem>
              <MenuItem onClick={handleTermOfUseClick}>
                <BlockHandleUserInfoItem>
                  <ListItemText>{MENU_LABELS.TERNS_OF_USE}</ListItemText>
                </BlockHandleUserInfoItem>
              </MenuItem>
              <MenuItem onClick={handlePrivacyPolicyClick}>
                <BlockHandleUserInfoItem>
                  <ListItemText>{MENU_LABELS.PRIVACY_POLICY}</ListItemText>
                </BlockHandleUserInfoItem>
              </MenuItem>
            </BlockHandleUserInfo>

            <Divider sx={{ margin: '0 26px', borderWidth: '1px' }} />
            <MenuItem onClick={handleLogoutClick}>
              <BlockLogout>
                <ListItemText>{MENU_LABELS.LOG_OUT}</ListItemText>
              </BlockLogout>
            </MenuItem>
          </Menu>
        </>
      )}
    </HeaderContainer>
  );
};

export default Header;
