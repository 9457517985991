/**
 * Dashboard page
 */

// React
import React, { useCallback, useEffect, useState } from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Material UI
import { alpha, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Component
import AppBanner from 'components/AppBanner';

// Common styles
import {
  Content,
  Divider,
  HeaderContainer,
  RegisterButton,
  ServiceItem,
  ServicesContainer,
  Title,
  WrapperIcon,
} from 'styles';

// Router
import ROUTES from 'configs/route';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';

// Utils
import { STORAGE_KEYS, STATUS_SERVICE_SYSTEM, FORM_BUTTON, AWS_S3 } from 'utils/constants';
import { handleGenerateAuthenCode } from 'utils/helper';
import { ServiceSystem } from 'utils/types/serviceType';

// Services
import useServicesAndBanners from 'hooks/useServicesAndBanner';

// Component
const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(isLoading);
  const userInfo = useRecoilValue(userInfoAtom);
  const [registeredServices, setRegisteredServices] = useState<any>([]);

  const userId = userInfo?.id || '';
  const { banners, services, fetchServiceAndBanners } = useServicesAndBanners();

  const handleUserInfoButton = async (service: ServiceSystem) => {
    if (getStatusService(service) === STATUS_SERVICE_SYSTEM.IN_USED) {
      setIsLoading(true);
      const serviceUrl = await handleGenerateAuthenCode(
        service?.client_id,
        service?.redirect_uri,
        navigate
      );

      if (serviceUrl) {
        window.open(serviceUrl, '_blank');
      }
      setIsLoading(false);
    } else {
      localStorage.setItem(STORAGE_KEYS.SERVICE_SYSTEM_SELECTED, JSON.stringify(service));
      navigate(ROUTES.REGISTER_SYSTEM);
    }
  };

  const getStatusService = useCallback(
    (service: any) => {
      return registeredServices?.find(
        (registeredService: any) => registeredService?.id === service?.id
      )?.status;
    },
    [registeredServices]
  );

  const getStatusText = useCallback(
    (service: any) => {
      const status = getStatusService(service);

      const statusTextMap: { [key: string]: string } = {
        [STATUS_SERVICE_SYSTEM.REQUESTED]: FORM_BUTTON.CURRENTLY_APPLYING,
        [STATUS_SERVICE_SYSTEM.IN_USED]: FORM_BUTTON.START_UP,
        [STATUS_SERVICE_SYSTEM.CANCELED]: FORM_BUTTON.REGISTER_SERVICE,
      };

      return statusTextMap[status] || FORM_BUTTON.REGISTER_SERVICE;
    },
    [getStatusService]
  );

  useEffect(() => {
    localStorage.removeItem(STORAGE_KEYS.USER_REGISTER);
    fetchServiceAndBanners();
  }, []);

  useEffect(() => {
    setRegisteredServices(userInfo?.services);
  }, [userId]);

  return (
    <Box sx={{ paddingX: { xs: '20px', md: '50px' } }}>
      <AppBanner banners={banners} />
      <ServicesContainer>
        <Grid container columnSpacing={{ xs: '10px', md: '20px' }} width="100%" rowSpacing="20px">
          {services &&
            services?.map((service: any, index) => (
              <ServiceItem
                key={index}
                size={{ xs: 6, md: 3 }}
                borderTop={`solid ${service?.color}`}
                sx={{
                  backgroundColor:
                    getStatusText(service) === FORM_BUTTON.START_UP
                      ? alpha(service?.color, 0.1)
                      : '',
                }}
              >
                <HeaderContainer>
                  <WrapperIcon src={`${AWS_S3.BASE_URL}/${service?.icon}`} alt="Image service" />
                  <Box>
                    <Title color={service?.color}>{service?.title}</Title>
                  </Box>
                </HeaderContainer>
                <Divider />
                <Box>
                  <Content>{service?.body}</Content>
                </Box>
                <RegisterButton
                  disabled={getStatusService(service) === STATUS_SERVICE_SYSTEM.REQUESTED}
                  onClick={() => handleUserInfoButton(service)}
                  variant={
                    getStatusText(service) ===
                    (FORM_BUTTON.START_UP || FORM_BUTTON.CURRENTLY_APPLYING)
                      ? 'contained'
                      : 'outlined'
                  }
                  color="secondary"
                  sx={{
                    '&:hover': {
                      borderColor: service?.color,
                    },
                    '&.Mui-disabled': {
                      backgroundColor: 'background.default',
                      color: 'text.secondary',
                    },
                  }}
                >
                  {getStatusText(service)}
                </RegisterButton>
              </ServiceItem>
            ))}
        </Grid>
      </ServicesContainer>
    </Box>
  );
};

export default Dashboard;
