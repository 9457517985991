/**
 * Home page
 */

// React
import React, { FC, useEffect } from 'react';

// Libraries
import { useNavigate, useLocation } from 'react-router-dom';

// Material UI
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Component
import AppBanner from 'components/AppBanner';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Router
import ROUTES from 'configs/route';

// Utils
import { AWS_S3, FORM_BUTTON, STORAGE_KEYS } from 'utils/constants';

// Common styles
import {
  Content,
  Divider,
  HeaderContainer,
  RegisterButton,
  ServiceItem,
  ServicesContainer,
  Title,
  WrapperIcon,
} from 'styles';

// Hooks
import useServicesAndBanners from 'hooks/useServicesAndBanner';

// Component
const HomePage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { banners, services, fetchServiceAndBanners } = useServicesAndBanners();

  const [previousPageAddress, setPreviousPageAddress] = useLocalStorage(
    STORAGE_KEYS.PREVIOUS_PAGE_ADDRESS
  );

  useEffect(() => {
    localStorage.removeItem(STORAGE_KEYS.USER_REGISTER);
    fetchServiceAndBanners();
  }, []);

  const handleClickRegister = () => {
    if (previousPageAddress !== location.pathname) {
      setPreviousPageAddress(location.pathname);
    }
    navigate(ROUTES.LOGIN);
  };

  return (
    <Box sx={{ paddingX: { xs: '20px', md: '50px' } }}>
      <AppBanner banners={banners} />
      <ServicesContainer>
        <Grid container columnSpacing={{ xs: '10px', md: '20px' }} width="100%" rowSpacing="20px">
          {services?.map((service: any, index) => (
            <ServiceItem key={index} size={{ xs: 6, md: 3 }} borderTop={`solid ${service?.color}`}>
              <HeaderContainer>
                <WrapperIcon src={`${AWS_S3.BASE_URL}/${service?.icon}`} alt="Image service" />
                <Box>
                  <Title color={service?.color}>{service?.title}</Title>
                </Box>
              </HeaderContainer>
              <Divider />
              <Box>
                <Content>{service.body}</Content>
              </Box>
              <RegisterButton
                type="button"
                onClick={() => handleClickRegister()}
                variant="outlined"
                sx={{
                  '&:hover': {
                    borderColor: service?.color,
                  },
                }}
              >
                {FORM_BUTTON.REGISTER_SERVICE}
              </RegisterButton>
            </ServiceItem>
          ))}
        </Grid>
      </ServicesContainer>
    </Box>
  );
};

export default HomePage;
