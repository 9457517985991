/* eslint-disable no-useless-catch */

/**
 * Banner service
 */

// Services
import BaseService from 'configs/service';

// Utils
import { HTTP_METHOD } from 'utils/constants';

const ENDPOINTS = {
  API_GET_BANNERS: ['/banners', HTTP_METHOD.GET],
};
class BannerService extends BaseService {
  constructor() {
    super();
  }

  async getBanners() {
    try {
      const results = await this.request(ENDPOINTS.API_GET_BANNERS, []);
      return results.data.results;
    } catch (error) {
      throw error;
    }
  }
}

export default new BannerService();
