/**
 * App input component
 */

// React
import React from 'react';

// Library
import { Controller, PathValue } from 'react-hook-form';
import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form';

// Material
import { TextFieldProps } from '@mui/material';

// Component
import TooltipInput from '../TooltipInput';

// Common styles
import { CustomTextField } from 'styles';
import { formatPhoneNumber } from 'utils/helper';

// Define a generic type T for form data, constrained to FieldValues
type AppInputProps<T extends FieldValues> = TextFieldProps & {
  name: Path<T>;
  control: Control<T>;
  type?: string;
  errors?: FieldErrors<T>;
  titleTooltip?: string;
  formatPhone?: boolean;
};

// Component
const AppInput = <T extends FieldValues>({
  name,
  control,
  type,
  errors,
  titleTooltip,
  formatPhone = false,
  ...rest
}: AppInputProps<T>): JSX.Element => {
  const formatValue = (value: PathValue<T, (string | undefined) & Path<T>>) => {
    if (!value) return '';
    if (formatPhone) return formatPhoneNumber(value);
    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField } }) => (
        <TooltipInput titleTooltip={titleTooltip}>
          <CustomTextField
            {...restField}
            inputRef={ref}
            value={formatValue(restField?.value)}
            fullWidth
            type={type}
            onKeyPress={(e) => {
              if (formatPhone && !/[0-9]/.test(e.key)) {
                e.preventDefault();
                return;
              }
            }}
            error={!!errors?.[name]}
            {...rest}
          />
        </TooltipInput>
      )}
    />
  );
};

export default AppInput;
