/**
 * UserInfo recoil
 */

// Recoil
import { atom } from 'recoil';
import { UserService } from 'utils/types';

// Type
export type DataUserInfoAtom = {
  id: number | null;
  email: string;
  password: string;
  password_confirmation: string;
  company_name: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  zipcode: string;
  city: string;
  prefecture: string;
  building?: string;
  phone_number: string;
  address: string;
  company_phone_number?: string;
  department?: string;
  old_email: string;
  old_password: string;
  role: string;
  services?: UserService[];
};

export const userInfoAtom = atom<DataUserInfoAtom>({
  key: 'userInfoAtom',
  default: {
    id: null,
    email: '',
    password: '',
    password_confirmation: '',
    company_name: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    zipcode: '',
    city: '',
    prefecture: '',
    building: '',
    phone_number: '',
    address: '',
    company_phone_number: '',
    department: '',
    old_email: '',
    old_password: '',
    role: '',
  },
});
