/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Helper utils
 */

// Libraries
import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';
import * as yup from 'yup';
import { DateTime } from 'luxon';

// Service
import AuthService from 'services/AuthService';

// Constant
import {
  NOTIFICATION_MESSAGES,
  PARAMS_GENERATE_AUTH_CODE,
  ROLE_USER,
  VALIDATION_MESSAGES,
} from 'utils/constants';
import {
  PASSWORD_POLICY,
  PASSWORD_STRENGTH,
  PHONE_NUMBER_POLICY,
  STORAGE_KEYS,
  Strength,
  TIME_REFRESH_TOKEN,
} from 'utils/constants';

// Router
import ROUTES from 'configs/route';

// Validation messages
const { PASSWORD } = VALIDATION_MESSAGES;

// FormatPhoneNumber
export const formatPhoneNumber = (value: string): string | boolean => {
  const region = 'JP';
  const util: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  try {
    const number: PhoneNumber = util.parseAndKeepRawInput(value, region);

    if (util.isValidNumberForRegion(number, region)) {
      return util.format(number, PhoneNumberFormat.NATIONAL);
    }
  } catch (error) {
    console.error(error);
  }

  return value;
};

export const validatePassword = (
  value: string | undefined,
  context: yup.TestContext<yup.AnyObject>
): boolean | yup.ValidationError => {
  const errors: string[] = [];

  if (value && value.length < 10) errors.push(PASSWORD.MIN_LENGTH);
  if (value && value.length > 32) errors.push(PASSWORD.MAX_LENGTH);
  if (value && !/[0-9]/.test(value)) errors.push(PASSWORD.REQUIRE_NUMBER);
  if (value && !/[a-z]/.test(value)) errors.push(PASSWORD.REQUIRE_LOWERCASE);
  if (value && !/[A-Z]/.test(value)) errors.push(PASSWORD.REQUIRE_UPPERCASE);
  if (value && !/[\W_]/.test(value)) errors.push(PASSWORD.REQUIRE_SPECIAL_CHAR);

  return errors.length > 0 ? context.createError({ message: errors.join(',  ') }) : true;
};

const hasLowerCase = (password: string) => /[a-z]+/.test(password);
const hasUpperCase = (password: string) => /[A-Z]+/.test(password);
const hasNumbers = (password: string) => /[0-9]+/.test(password);
const hasSpecialChars = (password: string) => /[^a-zA-Z0-9]+/.test(password);

const { MIN_LENGTH, MAX_LENGTH, STRONG_LENGTH } = PASSWORD_POLICY;

const { FULL_STRENGTH, WEAK_STRENGTH, MEDIUM_STRENGTH } = PASSWORD_STRENGTH;

export const checkPasswordStrength = (password: string): Strength => {
  const checks = [
    hasLowerCase(password),
    hasUpperCase(password),
    hasNumbers(password),
    hasSpecialChars(password),
  ];
  const strength = checks.filter(Boolean).length;
  if (strength === FULL_STRENGTH) {
    if (password.length >= STRONG_LENGTH && password.length <= MAX_LENGTH) {
      return FULL_STRENGTH;
    } else if (password.length < MIN_LENGTH || password.length > MAX_LENGTH) {
      return WEAK_STRENGTH;
    }
    return MEDIUM_STRENGTH;
  }
  return Math.min(strength, WEAK_STRENGTH) as Strength;
};

export const replacePhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/-/g, '');
};

export const validateCompanyPhoneNumberNoText = (
  value: string | undefined
): boolean | yup.ValidationError => {
  if (!value) return true;
  const number = replacePhoneNumber(value);
  return /^[0-9-]+$/.test(number);
};

export const validateCompanyPhoneNumberMinLength = (
  value: string | undefined
): boolean | yup.ValidationError | undefined => {
  const { PHONE_NUMBER_MIN_LENGTH } = PHONE_NUMBER_POLICY;
  if (!value) return true;
  const number = replacePhoneNumber(value);
  if (number?.length < PHONE_NUMBER_MIN_LENGTH) return false;
  else return true;
};

export const getCurrentDate = (): DateTime => {
  return DateTime.now();
};

export const parseJwt = (): Record<string, unknown> | undefined => {
  const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

  if (!accessToken) {
    return undefined;
  }

  const base64Url = accessToken.split('.')[1];

  if (!base64Url) {
    throw new Error('Invalid token structure');
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error('Invalid token');
  }
};

export const isTokenExpired = (): boolean => {
  const currentTime = getCurrentDate().toSeconds();
  const payload = parseJwt();
  const expToken = payload?.exp as number;
  return expToken !== undefined ? expToken < currentTime + TIME_REFRESH_TOKEN : true;
};

export const convertTimestampToDateTime = (timestamp: number, format: string): string => {
  const date = DateTime.fromSeconds(timestamp);

  return date.toFormat(format);
};

export const parseMessage = (value: string, list: (string | number)[]): string => {
  let newValue = value;
  if (newValue && list.length > 0) {
    list.forEach((element, index) => {
      const argument = '${' + index + '}';
      newValue = newValue.replace(argument, `${element}`);
    });
  }

  return newValue;
};

export const getErrorsMessage = (
  error: any,
  defaultMessage: string[] = [NOTIFICATION_MESSAGES.GENERAL.ERROR.SYSTEM]
): string[] => {
  const results = error?.response?.data?.results;

  const errorMessage = error?.response?.data?.message;

  if (results?.error_message) {
    return [results.error_message];
  }

  const errorValues =
    results && typeof results === 'object' && !Array.isArray(results) ? Object.values(results) : [];

  if (errorValues.every((value) => Array.isArray(value))) {
    const errorsMessages = errorValues.flat();
    return errorsMessages.length > 0 ? (errorsMessages as string[]) : [errorMessage];
  }

  return defaultMessage;
};

export const handleGenerateAuthenCode = async (
  clientId: string,
  redirectUri: string,
  navigate: (_path: string) => void
): Promise<string | undefined> => {
  const refreshToken = JSON.parse(localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN) || 'null');
  if (!refreshToken || !clientId || !redirectUri) return;

  try {
    const res = await AuthService.generateAuthenCode({
      refresh_token: refreshToken,
      redirect_uri: redirectUri,
      client_id: clientId,
    });
    const { authorization_code = '', redirect_uri = '' } = res;

    const serviceUrl = `${redirect_uri}?code=${authorization_code}`;
    return serviceUrl;
  } catch (error) {
    navigate(ROUTES.ERROR);
  }
};

export const handleLoginFromSSO = async (
  data: any,
  setAccessToken: (_token: string) => void,
  setRefreshToken: (_token: string) => void,
  setUserInfo: (_user: any) => void,
  setIsLoading: (_loading: boolean) => void,
  navigate: (_path: string) => void,
  handleLoginError: (_error: any) => void
) => {
  try {
    const result = await AuthService.login(data);
    setAccessToken(result?.access_token);
    setRefreshToken(result?.refresh_token);

    const user = await AuthService.getUserInfo();

    if (user?.role === ROLE_USER.AF_USER) {
      const serviceUrl = await handleGenerateAuthenCode(
        PARAMS_GENERATE_AUTH_CODE.CLIENT_ID,
        PARAMS_GENERATE_AUTH_CODE.REDIRECT_URI,
        navigate
      );
      if (serviceUrl) {
        localStorage.clear();
        window.location.href = serviceUrl;
      }
    } else if (user?.role === ROLE_USER.COMPANY_USER) {
      setUserInfo({ ...user });
      setIsLoading(false);
      navigate(ROUTES.DASHBOARD);
    } else {
      navigate(ROUTES.ERROR);
    }
  } catch (error) {
    handleLoginError(error);
    console.error(error);
  }
};

export const handleLoginFromClient = async (
  data: any,
  clientId: string,
  redirectUri: string,
  handleLoginError: (_error: any) => void
): Promise<void> => {
  try {
    const result = await AuthService.login({
      ...data,
      client_id: clientId,
      redirect_uri: redirectUri,
    });

    const newUrl = `${redirectUri}?code=${result.authorization_code}`;
    window.location.href = newUrl;
  } catch (error) {
    handleLoginError(error);
    console.error(error);
  }
};
