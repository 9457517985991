/**
 * Register page
 */

// React
import React, { FC } from 'react';

// Libraries
import { Navigate, useSearchParams } from 'react-router-dom';

// MUI
import { Box, Button, Container, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

// icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Router
import ROUTES from 'configs/route';

// Utils
import { URL_PRIVACY_TERM } from 'utils/constants/common';

// Components
import { AppCheckbox } from 'components/common';
import UserInfo from 'components/feature/UserInfo';
import { AppFieldErrors } from 'components/common/AppFieldErrors';

// Hook
import { useRegister } from './useRegister';

// Common Styles
import { WrapperForm } from 'styles/commonStyles';

// Styles
const RegisterContainer = styled(Container)(({ theme }) => ({
  '& .input-field-email-disable .Mui-disabled': {
    background: `${theme.palette.primary.main} !important`,
    '-webkit-text-fill-color': `${theme.palette.primary.contrastText} !important`,
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

const WrapperButton = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: 60,
  maxWidth: 260,
  width: '100%',
  '& > *:not(:first-of-type)': {
    marginTop: 20,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 200,
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: 50,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 40,
  },
}));

const LinkPolicy = styled('a')(({ theme }) => ({
  padding: '0px 24px',
  borderRadius: 5,
  border: '2px solid #DDDDDD',
  background: '#ffffff',
  height: 60,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#000000',
  textDecoration: 'none',
  '& .MuiTypography-root': {
    flexGrow: '1',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    height: 40,
  },
}));

// Form fields
export type FormData = {
  email?: string;
  password: string;
  password_confirmation: string;
  company_name: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  zipcode: string;
  city: string;
  prefecture: string;
  building?: string;
  phone_number: string;
  address: string;
  company_phone_number?: string;
  department?: string;
  services?: any[];
  id?: number;
  company_id?: string;
  username?: string;
  is_company_name?: boolean;
};

const policyItem = [
  {
    href: URL_PRIVACY_TERM.PRIVACY_POLICY_URL,
    label: 'プライバシーポリシー',
  },
  {
    href: URL_PRIVACY_TERM.TERM_OF_USE_URL,
    label: '利用規約',
  },
];

// Register component
const RegisterPage: FC = () => {
  const [searchParams] = useSearchParams();
  const username = decodeURIComponent(searchParams.get('user_name') || '');
  const confirmCode = searchParams.get('confirmation_code') || '';
  const expiredTime = searchParams.get('expired_time') || '';

  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    disableForm,
    setDisableForm,
    formData,
    setError,
    clearErrors,
    setValue,
    checkboxPrivacyPolicy,
    disableButtonSubmit,
    checkboxTermOfUse,
    errorMessage,
  } = useRegister({ username, confirmCode, expiredTime });

  const disableCompanyName = formData?.is_company_name;

  if (!username || !confirmCode || !expiredTime) {
    return <Navigate to={ROUTES.ERROR} />;
  }

  return (
    <RegisterContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperForm>
          <BoxHeader>
            <Typography variant="h2">アカウント本登録</Typography>
          </BoxHeader>

          <UserInfo
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            disableForm={disableForm}
            disableCompanyName={disableCompanyName}
          />

          {!disableForm && (
            <Grid container size={12} spacing={1} mt="50px">
              {policyItem.map((item) => (
                <Grid size={{ xs: 12, sm: 6 }} key={item.label}>
                  <LinkPolicy href={item.href} target="_blank" rel="noopener noreferrer">
                    <Typography>{item.label}</Typography>
                    <KeyboardArrowRightIcon />
                  </LinkPolicy>
                </Grid>
              ))}
            </Grid>
          )}

          <Grid size={12} mt={2}>
            <FormGroup>
              <AppCheckbox
                disabled={disableForm}
                onChange={checkboxPrivacyPolicy}
                label="プライバシーポリシーに同意する"
              />
              <AppCheckbox
                disabled={disableForm}
                onChange={checkboxTermOfUse}
                label="利用規約に同意する"
              />
            </FormGroup>
          </Grid>
        </WrapperForm>

        <Box mt={{ xs: 3, sm: 5 }}>
          <AppFieldErrors errorMessage={errorMessage} />
        </Box>

        <WrapperButton>
          {disableForm && (
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => setDisableForm(false)}
            >
              修正する
            </Button>
          )}

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disableButtonSubmit}
          >
            {disableForm ? '登録する' : '登録内容を確認'}
          </Button>
        </WrapperButton>
      </form>
    </RegisterContainer>
  );
};

export default RegisterPage;
