/**
 * Register system page
 */

// React
import React, { FC } from 'react';

// MUI imports
import Grid from '@mui/material/Grid2';
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Utils
import { formatPhoneNumber } from 'utils/helper';

import { useRegisterSystem } from './useRegisterSystem';
import { AppFieldErrors } from 'components/common/AppFieldErrors';
import { AWS_S3 } from 'utils/constants';

// Styled
const WrapperRegisterSystemPage = styled(Container)({});

const WrapperButton = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: 60,
  maxWidth: 360,
  width: '100%',
  '& > *:not(:first-of-type)': {
    marginTop: 20,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 270,
  },
}));

const FieldInput = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 60,
  background: theme.palette.background.default,
  borderRadius: 5,
  padding: '18px 35px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TypographyCustom = styled(Typography)({
  fontWeight: '700',
});

const TextareaCustom = styled('textarea')({
  width: '100%',
  border: '1px solid #ccc',
  borderRadius: '2px',
  padding: '10px',
  fontSize: '16px',
  '&:hover': {
    border: '1px solid unset',
  },
});

const FormContainer = styled(Grid)(({ theme }) => ({
  padding: '0px 70px 65px',
  borderRadius: '10px',
  borderTop: '12px solid',
  background: theme.palette.background.paper,
  boxShadow: 'inset 0px 0px 2px 0px #DDDDDD',
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px',
    gap: '20px',
  },
}));

const BoxHeaderSystem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 18,
  height: 120,
  borderBottom: `2px solid ${theme.palette.grey[50]}`,
  [theme.breakpoints.down('md')]: {
    height: 100,
  },
  '& img': {
    width: 60,
  },
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '22px !important',
  fontWeight: '700',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px !important',
  },
}));

// Component
const RegisterSystem: FC = () => {
  const {
    userInfo,
    statusServiceSystemRequested,
    handleSubmit,
    serviceSystemSelected,
    handleChangeDescription,
    description,
    handleBackDashboardPage,
    errorMessage,
  } = useRegisterSystem();

  const userData = [
    { label: 'ユーザID', value: userInfo?.email || '' },
    { label: '会社名 / 団体名', value: userInfo?.company_name || '' },
    { label: '所属', value: userInfo?.department || '' },
    { label: 'お名前', value: `${userInfo?.last_name || ''}  ${userInfo?.first_name || ''}` },
    {
      label: 'フリガナ',
      value: `${userInfo?.last_name_kana || ''}  ${userInfo?.first_name_kana || ''}`,
    },
    {
      label: '住所',
      value: (
        <Box>
          <Typography>{userInfo?.zipcode ? `〒 ${userInfo.zipcode}` : ''}</Typography>
          <Typography>
            {`${userInfo?.prefecture || ''} ${userInfo?.city || ''} ${userInfo?.address || ''}`}
          </Typography>
          <Typography>{userInfo?.building || ''}</Typography>
        </Box>
      ),
    },
    {
      label: '会社電話番号',
      value: userInfo?.company_phone_number ? formatPhoneNumber(userInfo.company_phone_number) : '',
    },
    {
      label: '携帯電話番号',
      value: userInfo?.phone_number ? formatPhoneNumber(userInfo.phone_number) : '',
    },
  ];

  return (
    <WrapperRegisterSystemPage>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <FormContainer
            size={12}
            sx={{ borderTopColor: serviceSystemSelected?.color || 'transparent' }}
          >
            <Grid size={12}>
              <BoxHeaderSystem>
                <img
                  src={`${AWS_S3.BASE_URL}/${serviceSystemSelected?.icon}`}
                  alt="Image service"
                />
                <TypographyTitle sx={{ color: serviceSystemSelected?.color || '#212529' }}>
                  {serviceSystemSelected?.title || ''}
                </TypographyTitle>
              </BoxHeaderSystem>
            </Grid>

            <Grid size={12}>
              <TypographyTitle py={5}>登録情報</TypographyTitle>
            </Grid>

            {userData.map((field, index) => (
              <Grid size={12} key={index} pb={3}>
                <TypographyCustom mb={1}>{field.label}</TypographyCustom>
                <FieldInput>{field.value}</FieldInput>
              </Grid>
            ))}

            <Grid size={12}>
              <TypographyCustom mb={1}>導入にあたっての質問事項</TypographyCustom>
              <TextareaCustom
                sx={{
                  background: statusServiceSystemRequested ? '#faf9f8' : 'transparent',
                  border: statusServiceSystemRequested ? 'none' : '',
                  '&:focus': {
                    outline: 'none',
                    border: statusServiceSystemRequested ? 'none' : '',
                  },
                }}
                onChange={(e) => handleChangeDescription(e)}
                readOnly={statusServiceSystemRequested}
                rows={3}
                value={description}
                placeholder={statusServiceSystemRequested ? '' : 'サービス詳細と金額が知りたい'}
              />
            </Grid>
          </FormContainer>

          <Box mt={{ xs: 3, sm: 5 }}>
            <AppFieldErrors errorMessage={errorMessage} />
          </Box>

          <WrapperButton>
            <Button
              onClick={handleBackDashboardPage}
              fullWidth
              type="button"
              variant="outlined"
              color="secondary"
            >
              戻る
            </Button>
            <Button
              disabled={statusServiceSystemRequested}
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
            >
              上記、登録情報で問い合わせる
            </Button>
          </WrapperButton>
        </Grid>
      </form>
    </WrapperRegisterSystemPage>
  );
};

export default RegisterSystem;
