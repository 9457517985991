/**
 * Common styles
 */

// Material
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Styled
export const LogoContainer = styled(Box)(({ theme }) => ({
  padding: '0 10px 60px 10px',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '40px',
  },
}));

export const WrapperForm = styled(Box)(({ theme }) => ({
  padding: '65px 70px',
  borderRadius: '10px',
  background: theme.palette.background.default,
  boxShadow: 'inset 0px 0px 1.5px 0.5px #DDDDDD',
  [theme.breakpoints.down('sm')]: {
    padding: '40px 20px',
    gap: '20px',
  },
}));

export const FormButton = styled(Button)(({ theme }) => ({
  height: '40px',
  width: '260px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
    width: '200px',
  },
}));

export const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  [theme.breakpoints.down('sm')]: {
    gap: '20px',
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '60px',
    backgroundColor: '#FFFFFF',
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      padding: '0 16.5px',
    },
  },
}));

export const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
}));

export const FieldErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
  width: '100%',
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: '20px',
  color: `${theme.palette.error.main}`,
}));

export const FieldLabel = styled(Typography)({
  minWidth: '200px',
  fontWeight: 'bold',
});

export const LayoutContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const ContentLayout = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  paddingTop: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '20px',
  },
}));

export const ContainerCustom = styled(Container)({});

export const WrapperInputOrMessage = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
});

export const WrapperContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '5px',
  width: '100%',
});

export const TypographyCustom = styled(Typography)(({ theme }) => ({
  marginBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
  },
}));

export const ServicesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '50px 0px',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 0px',
    gap: '10px',
  },
}));

export const ServiceItem = styled(Grid)(({ theme }) => ({
  padding: '29px 35px 35px 35px',
  borderRadius: '10px',
  backgroundColor: 'white',
  display: 'flex',
  borderTopWidth: '12px',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    borderTopWidth: '8px',
    padding: '23px 15px 20px 15px',
  },
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '29px',
  gap: '20px',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
    paddingBottom: '25px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '22px !important',
  lineHeight: '25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px !important',
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  padding: '35px 0',
  fontWeight: '600',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px !important',
    padding: '20px 0',
  },
}));

export const Divider = styled('hr')({
  margin: '0px',
  border: '1px solid #DDDDDD',
});

export const RegisterButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  height: '40px',
  padding: '0px 20px',
  borderRadius: '30px',
  width: '100%',
  fontSize: '18px',
  marginTop: 'auto',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    height: '30px',
  },
}));

export const WrapperIcon = styled('img')(({ theme }) => ({
  width: '59px',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '30px',
  },
}));
